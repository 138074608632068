import react from "react";

import "./About.css";
import AboutImage from "./images/AboutImage.jpeg";

const About = () => {
  return (
    <div className="container">
      <div className="about">
        <div className="about-title">
          <h2> ABOUT US </h2>
        </div>
        <div>
          <span className="about-span">
            <hr className="about-seperator" />
          </span>
        </div>
        <img className="about-img" src={AboutImage}></img>
        <h3>
          Agest Electrics Ltd. is established in 2008 as an electrical
          engineering and manufacturing company mainly focused on the Marine
          Industry which is located in Antalya Free Zone. As an Electrical
          System Integrator, focused on design, development, production and
          installation of Electrical and Automation systems. In more specific,
          our scope of supply consists of LV Switchboards, Integrated Alarm
          Monitoring Systems, Control Systems and Electrical Installation for
          Marine applications.
        </h3>
      </div>
    </div>
  );
};

export default About;

import React, { useEffect, useState } from "react";

import LogoPlace from "../logo/LogoPlace";
import "./TopBar.css";

import MainNavigation from "../navigation/MainNavigation";

const TopBar = (props) => {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 90)
      );
    }
  });

  return (
    <header className={small ? "topbar-scrolled" : "topbar"}>
      {props.children}
    </header>
  );
};

//    return (
//<div className="topbar">

//<LogoPlace/>

//<MainNavigation />

//</div>

//  )
//}

export default TopBar;

import react from "react";

import "./Contact.css";

const Contact = () => {
  return (
    <div className="container">
      <div className="contact">
        <div className="contact-title">
          <h2> CONTACT </h2>
        </div>
        <div>
          <span className="contact-span">
            <hr className="contact-seperator" />
          </span>
        </div>

        <h3>UNDER CONSTRUCTION !!!</h3>
      </div>
    </div>
  );
};

export default Contact;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import "./DrawerDropCompany.css";

const DrawerDropCompany = (props) => {
  const [dropdownC, setDropdownC] = useState(false);

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", () =>
        setSmall(window.pageYOffset > 90)
      );
    }
  }, []);

  return (
    <ul className={small ? "dropdown_submenu-scrolled" : "dropdown_submenu"}>
      <li className="submenu-item_company">
        <NavLink
          to="/about-us"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          About Us{" "}
        </NavLink>
      </li>
      <li className="submenu-item_company">
        <NavLink
          to="/quality"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          Quality Policy
        </NavLink>
      </li>
      <li className="submenu-item_company">
        <NavLink
          to="/health-safety"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          Health & Safety Policy
        </NavLink>
      </li>
    </ul>
  );
};

export default DrawerDropCompany;

import React from "react";
import { NavLink } from "react-router-dom";

import ButtomBar from "../UIElement/ButtomBar";

import "../UIElement/fontello/css/fontello.css";
import "./Footer.css";

const Footer = (props) => {
  return (
    <ButtomBar>
      <div className="footer-grid">
        <div className="footer-col-grid">
          <span>COMPANY</span>
          <nav>
            <ul>
              <li>
                {" "}
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li>
                {" "}
                <NavLink to="/quality">Quality Policy</NavLink>
              </li>
              <li>
                {" "}
                <NavLink to="/health-safety">Health & Safety Policy</NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="footer-col-grid">
          <span> SERVICES</span>

          <nav>
            <ul>
              <li>
                <NavLink to="/electrical-engineering">
                  Electrical Engineering & Design
                </NavLink>
              </li>
              <li>
                <NavLink to="/electrical-switchboards">
                  Electrical Switchboards
                </NavLink>
              </li>
              <li>
                <NavLink to="/control-panels">Control Panels</NavLink>
              </li>
              <li>
                <NavLink to="/automation-systems">Automation Systems</NavLink>
              </li>
              <li>
                <NavLink to="/installation">Installation</NavLink>
              </li>
              <li>
                <NavLink to="/project-management">
                  Project Management & Consultancy
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>

        <div className="footer-col-grid">
          <span> CONTACT</span>

          <h4>Antalya Serbest Bölgesi S Ada 5.Sok No:2/2 Konyaaltı ANTALYA</h4>
          <h4>
            <p>Email : info@agest.com.tr </p>
            <p>Phone :+90 242 502 04 00</p>
            <p>Fax :+90 242 502 06 11</p>
          </h4>
          <h4>Mo-Fr: 8:00-18:15</h4>
        </div>

        <div className="footer-col-grid">
          <span> WHERE WE ARE</span>

          <box className="box">
            <iframe
              width="250vh"
              height="250vh"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Agest%20Elektrik%20Elektronik%20Otomasyon%20Liman,%20Serbest%20B%C3%B6lge%20No:72,%2007130%20Konyaalt%C4%B1/Antalya&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </box>
        </div>
      </div>
    </ButtomBar>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";

import "./YellowBar.css";

const YellowBar = () => {
  return (
    <div>
      <div className="yellowbar-grid">
        <div className="yellowbar-col-grid">
          <h1>Continuous improvement with perseverance...</h1>
        </div>
        <div className="yellowbar-col1-grid">
          <Link to="/contact-us" style={{ textDecoration: "none" }}>
            <button>GET IN TOUCH</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default YellowBar;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import DrawerDropCompany from "./DrawerDropCompany";
import DrawerDropServices from "./DrawerDropServices";
import "./NavLinks.css";

const NavLinks = (props) => {
  const [dropdowncompany, setDropdowncompany] = useState(false);
  const [dropdownservices, setDropdownservices] = useState(false);

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 90)
      );
    }
  }, []);

  return (
    <ul className={small ? "nav-links-scrolled" : "nav-links"}>
      <li>
        <NavLink to="/">Home</NavLink>
      </li>
      <li
        onMouseEnter={() => setDropdowncompany(true)}
        onMouseLeave={() => setDropdowncompany(false)}
      >
        <span>Company </span>
        {dropdowncompany && <DrawerDropCompany />}
      </li>
      <li
        onMouseEnter={() => setDropdownservices(true)}
        onMouseLeave={() => setDropdownservices(false)}
      >
        <span>Services</span>
        {dropdownservices && <DrawerDropServices />}
      </li>
      <li>
        <NavLink to="/references">References</NavLink>
      </li>
      <li>
        <NavLink to="/contact-us">Contact</NavLink>
      </li>
    </ul>
  );
};

export default NavLinks;

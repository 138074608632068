import React, { useState, useEffect } from "react";

import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import NavMobileItem from "./NavMobileItem";
import SideDrawer from "./SideDrawer";
import Backdrop from "./BackDrop";
import LogoPlace from "../logo/LogoPlace";
import TopBar from "../UIElement/TopBar";

import "./MainNavigation.css";

const MainNavigation = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };
  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 90)
      );
    }
  });

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen}>
        <nav
          className={
            small
              ? "main-navigation__drawer-scrolled-nav"
              : "main-navigation__drawer-nav"
          }
        >
          <NavMobileItem closeDrawerHandler={closeDrawerHandler} />
        </nav>
      </SideDrawer>

      <div className={small ? "topbar-container-scrolled" : "topbar-container"}>
        <TopBar>
          <LogoPlace />
          <MainHeader>
            <nav
              className={
                small
                  ? "main-navigation-scrolled__header-nav"
                  : "main-navigation__header-nav"
              }
            >
              <NavLinks />
            </nav>
          </MainHeader>
          <button
            className={
              small
                ? "main-navigation__menu-btn-scrolled"
                : "main-navigation__menu-btn"
            }
            onClick={drawerIsOpen ? closeDrawerHandler : openDrawerHandler}
          >
            <span />
            <span />
            <span />
          </button>
        </TopBar>
      </div>
    </React.Fragment>
  );
};

export default MainNavigation;

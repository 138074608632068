import React from "react";

import "./WhyUs.css";
import "../../../../shared/UIElement/fontello/css/fontello.css";

const WhyUs = () => {
  return (
    <div>
      <div className="whyus-title">
        <h2> WHY US? </h2>
      </div>
      <div>
        <span className="whyus-span">
          <hr className="whyus-seperator" />
        </span>
      </div>

      <div className="whyus-grid">
        <div className="whyus-col-grid">
          <i className="icon-thumbs-up icon-circle"></i>
          <h3>HIGH QUALITY</h3>
          <h4>
            As a princible, Our main goal is to provide excellence in quality of
            products and services.
          </h4>
        </div>
        <div className="whyus-col-grid">
          <i className="icon-newspaper icon-circle"></i>

          <h3>CERTIFIED EXPERIENCE</h3>
          <h4>
            Our team is made up of certified professionals who have been serving
            in the marine industry for many years.
          </h4>
        </div>
        <div className="whyus-col-grid">
          <i className="icon-back-in-time icon-circle"></i>

          <h3>RESPONSIVE</h3>
          <h4>
            It is our priority to meet all customer needs in the fastest way
            with full customer satisfaction.
          </h4>
        </div>
        <div className="whyus-col-grid">
          <i className="icon-flash icon-circle"></i>

          <h3>FACILITATING</h3>
          <h4>
            We are aware of complicated marine systems can create difficulties.
            Agest Team is always focus on to ease it.
          </h4>
        </div>
        <div className="whyus-col-grid">
          <i className="icon-globe icon-circle"></i>

          <h3>GUARANTEE</h3>
          <h4>
            All services are under the responsibility of Agest internationally
            to meet customer satisfaction.
          </h4>
        </div>
        <div className="whyus-col-grid">
          <i className="icon-lifebuoy icon-circle"></i>

          <h3>ON TIME SUPPORT</h3>
          <h4>
            Time is everything especially if there is a problem to be solved.
            Agest Team is reachable 7/24 for necessary support.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;

import react from "react";

import "./HealthSP.css";

const HealthSP = () => {
  return (
    <div className="container">
      <div className="safety">
        <div className="safety-title">
          <h2> HEALTH & SAFETY POLICY </h2>
        </div>
        <div>
          <span className="safety-span">
            <hr className="safety-seperator" />
          </span>
        </div>

        <h3>
          <p>
            Our vision is to provide secure and qualified service to the
            customers with highest satisfaction. Our most important goal during
            customer service is to keep the occupational health and safety of
            employees, customers and the workplace at highest level.
          </p>
          <p>
            As being Agest Elektrik Elektronik San.Tic.Ltd.Şti. , we commit ;
          </p>
          <li>
            Establishing and implementing Occupational Health and Safety system,
            to control the system effectiveness and improve the performance of
            the system continously,
          </li>
          <li>
            Developing a general policy of prevention which covers the
            technology, business organization, working conditions, social
            relations and factors related to the workplace,
          </li>
          <li>
            Identifying, evulating and preventing the risks related with
            Occupational Health and Safety and struggle with risks at the its
            source,
          </li>
          <li>
            Taking the necessary precautions to prevent injuries and health
            deterioration that may occur in the office area and workplaces,
          </li>
          <li>
            Complying with recent legal regulations and industrial standards for
            Occupational Health and Safety and working conditions,
          </li>
          <li>
            Taking all necessary precautions including training, exercise and
            informing; carrying out the necessary organization; providing
            adequate resources including tools and equipments to protect the
            employees’ health and safety, to prevent the occupational risks and
            to provide a sade working environment,
          </li>
          <li>
            Providing a safe working environment, ensuring the effectiveness of
            the Occupational Health and Safety system, making the workplace and
            office area controls which will provide effective and economical use
            of natural resources and check all kind of processes affecting the
            environment,
          </li>

          <h4>TURGUT ERDEM ALAY</h4>
          <h4>Managing Director</h4>
        </h3>
      </div>
    </div>
  );
};

export default HealthSP;

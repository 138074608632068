import react from "react";
import "./Quality.css";

const Quality = () => {
  return (
    <div className="container">
      <div className="quality">
        <div className="quality-title">
          <h2> QUALITY POLICY </h2>
        </div>
        <div>
          <span className="quality-span">
            <hr className="quality-seperator" />
          </span>
        </div>

        <h3>
          <p>
            Agest Elektrik Elektronik San.Tic.Ltd.Şti. started on his way for to
            create different perspectives to the customers with innotative
            approaches, to use the advanced technology in the best way and to
            develop solutions that transform knowledge into the value.
          </p>
          <p>We collect our quality values under four discipline;</p>
          <li>
            To provide most effictive and economical solutions to the customers
          </li>
          <li>To provide excellence in quality of products and services </li>
          <li>To reach the highest level of customer satisfaction</li>
          <li>Efficient and effective Project Management</li>
          <p>
            In the line with these values, Agest Elektrik Elektronik San.
            Tic.Ltd.Şti. takes;
          </p>
          <li>
            Always, to meet the customer requirements and to ensure highest
            customer satisfaction by establishing custumer-focused system,
          </li>
          <li>
            To raise the training and the competence of its employees to the
            highest level
          </li>
          <li>To work with team spirit</li>
          <li>
            To improve continously quality system and to increase its efficiency
            and effectiveness
          </li>
          <li>
            To keep the product and service quality at the top level at every
            stage
          </li>
          <li>Continuous improvement of its processes</li>
          <p>as principle.</p>
          <p>
            As a company, it is our basic and permanent goal to develop all our
            business processes, to increase the competence of our employees, to
            prioritize and to increase the effeciency and quaity of our products
            and services.
          </p>
          <h4>TURGUT ERDEM ALAY</h4>
          <h4>Managing Director</h4>
        </h3>
      </div>
    </div>
  );
};

export default Quality;

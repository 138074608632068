import react from "react";

import "./Pmc.css";

const Pmc = () => {
  return (
    <div className="container">
      <div className="pmc">
        <div className="pmc-title">
          <h2> PROJECT MANAGEMENT & CONSULTANCY</h2>
        </div>
        <div>
          <span className="pmc-span">
            <hr className="pmc-seperator" />
          </span>
        </div>

        <h3>UNDER CONSTRUCTION !!!</h3>
      </div>
    </div>
  );
};

export default Pmc;

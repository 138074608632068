import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Aut from "./pages/aut/Aut";
import ControlP from "./pages/controlp/ControlP";
import ElectricalS from "./pages/electricals/ElectricalS";
import Engineering from "./pages/engineering/Engineering";
import Ins from "./pages/ins/Ins";
import Pmc from "./pages/pmc/Pmc";
import Quality from "./pages/quality/Quality";
import HealthSP from "./pages/healthsp/HealthSP";
import References from "./pages/references/References";
import Contact from "./pages/contact/Contact";

import MainNavigation from "./shared/navigation/MainNavigation";
import ScrollToTop from "./shared/UIElement/ScrollToTop";
import Footer from "./shared/footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <MainNavigation />

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/automation-systems" element={<Aut />} />
          <Route path="/control-panels" element={<ControlP />} />
          <Route path="/electrical-switchboards" element={<ElectricalS />} />
          <Route path="/electrical-engineering" element={<Engineering />} />
          <Route path="/installation" element={<Ins />} />
          <Route path="/project-management" element={<Pmc />} />
          <Route path="/quality" element={<Quality />} />
          <Route path="/health-safety" element={<HealthSP />} />
          <Route path="/references" element={<References />} />
          <Route path="/contact-us" element={<Contact />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import react from "react";

import "./Ins.css";

const Ins = () => {
  return (
    <div className="container">
      <div className="ins">
        <div className="ins-title">
          <h2> ELECTRICAL INSTALLATION</h2>
        </div>
        <div>
          <span className="ins-span">
            <hr className="ins-seperator" />
          </span>
        </div>

        <h3>UNDER CONSTRUCTION !!!</h3>
      </div>
    </div>
  );
};

export default Ins;

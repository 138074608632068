import react from "react";

import "./ElectricalS.css";

const ElectricalS = () => {
  return (
    <div className="container">
      <div className="electricals">
        <div className="electricals-title">
          <h2> ELECTRICAL SWITCHBOARDS </h2>
        </div>
        <div>
          <span className="electricals-span">
            <hr className="electricals-seperator" />
          </span>
        </div>

        <h3>UNDER CONSTRUCTION !!!</h3>
      </div>
    </div>
  );
};

export default ElectricalS;

import react from "react";

import "./ControlP.css";

const ControlP = () => {
  return (
    <div className="container">
      <div className="controlp">
        <div className="controlp-title">
          <h2> CONTROL PANELS </h2>
        </div>
        <div>
          <span className="controlp-span">
            <hr className="controlp-seperator" />
          </span>
        </div>

        <h3>UNDER CONSTRUCTION !!!</h3>
      </div>
    </div>
  );
};

export default ControlP;

import React, {
  useState,
  useEffect,
  Component,
  componentDidMount,
} from "react";
import { NavLink } from "react-router-dom";

import "./DrawerDropServices.css";

const DrawerDropServices = (props) => {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", () =>
        setSmall(window.pageYOffset > 90)
      );
    }
  }, []);

  return (
    <ul
      className={
        small
          ? "dropdown_submenu_services-scrolled"
          : "dropdown_submenu_services"
      }
    >
      <li className="submenu-item_services">
        <NavLink
          to="/electrical-engineering"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          Electrical Engineering & Design{" "}
        </NavLink>
      </li>

      <li className="submenu-item_services">
        <NavLink
          to="/electrical-switchboards"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          Electrical Switchboards
        </NavLink>
      </li>

      <li className="submenu-item_services">
        <NavLink
          to="/control-panels"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          Control Panels
        </NavLink>
      </li>
      <li className="submenu-item_services">
        <NavLink
          to="/automation-systems"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          Automation Systems
        </NavLink>
      </li>
      <li className="submenu-item_services">
        {" "}
        <NavLink
          to="/installation"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          Installation
        </NavLink>
      </li>
      <li className="submenu-item_services">
        <NavLink
          to="/project-management"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => props.closeDrawerHandler()}
        >
          Project Management & Consultancy
        </NavLink>
      </li>
    </ul>
  );
};

export default DrawerDropServices;

import react from "react";
import ImageSlider from "./components/slider/ImageSlider";
import { SliderData } from "./components/slider/SliderData";
import YellowBar from "./components/yellowbar/YellowBar";
import OurServices from "./components/ourservices/OurServices";
import WhyUs from "./components/whyus/WhyUs";
import SolutionPartners from "./components/solutionpartners/SolutionPartners";

import "./components/slider/Slider.css";

const Home = () => {
  return (
    <div>
      <div className="slider-container">
        <ImageSlider slides={SliderData} />
      </div>
      <div className="yellowbar-container">
        <YellowBar />
      </div>
      <div className="ourservices-container">
        <OurServices />
      </div>
      <div className="whyus-container">
        <WhyUs />
      </div>
      <div className="solutionpartners-container">
        <SolutionPartners />
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./LogoPlace.css";
import logo from "./logo.png";
import logo2 from "./logo2.png";

const LogoPlace = () => {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setSmall(window.pageYOffset > 1));
    }
  });

  return (
    <span className={small ? "logo__place-scrolled" : "logo__place"}>
      <nav>
        <Link to="/">
          <img className="logo" src={small ? logo2 : logo} alt=""></img>
        </Link>
      </nav>
    </span>
  );
};

export default LogoPlace;

import React, { useEffect, useState } from "react";

import "./MainHeader.css";

const MainHeader = (props) => {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 90)
      );
    }
  });

  return (
    <nav className={small ? "main-header-scrolled" : "main-header"}>
      {props.children}
    </nav>
  );
};

export default MainHeader;

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import "./SideDrawer.css";
import NavMobileItem from "./NavMobileItem";
import items from "./Data/SideDrawerData.json";

const SideDrawer = (props) => {
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("mousemove", () =>
        setSmall(window.pageYOffset > 90)
      );
    }
  }, []);
  const content = (
    <CSSTransition
      in={props.show}
      timeout={200}
      classNames="slide-in-right"
      mountOnEnter
      unmountOnExit
    >
      <aside className={small ? "side-drawer-scrolled" : "side-drawer"}>
        {items.map((item, index) => (
          <NavMobileItem key={index} item={item} />
        ))}
      </aside>
    </CSSTransition>
  );
  return ReactDOM.createPortal(content, document.getElementById("drawer-hook"));
};

export default SideDrawer;

import React from "react";
import { Link } from "react-router-dom";

import "./OurServices.css";
import eeimage from "./images/ee.jpeg";
import esimage from "./images/es.jpeg";
import cpimage from "./images/cp.jpeg";
import autimage from "./images/aut.jpeg";
import insimage from "./images/ins.jpeg";
import pmimage from "./images/pm.png";

const OurServices = () => {
  return (
    <div>
      <div className="ourservices-title">
        <h2> OUR SERVICES </h2>
      </div>
      <div>
        <span className="ourservices-span">
          <hr className="ourservice-grid-seperator" />
        </span>
      </div>

      <div className="ourservices-grid">
        <div className="col-grid">
          <nav>
            <Link to="/electrical-engineering">
              <img className="col-grid-image" src={eeimage} alt=""></img>
            </Link>
          </nav>
          <h3>Electrical Engineering & Design</h3>
          <span>
            <hr className="col-grid-seperator" />
          </span>
          <h4>
            Project and Design Team is dedicated to prepare all necessary
            designs and drawings for Classification Societies, production and
            installation on site.
          </h4>
        </div>

        <div className="col-grid">
          <nav>
            <Link to="/electrical-switchboards">
              <img className="col-grid-image" src={esimage} alt=""></img>
            </Link>
          </nav>
          <h3>Electrical Switchboards</h3>
          <span>
            <hr className="col-grid-seperator" />
          </span>
          <h4>
            Our workshop facility is capable of manufacture and test all kind of
            low voltage switcboards up to 690VAC in compatible with
            Classification Society Regulations.
          </h4>
        </div>

        <div className="col-grid">
          <nav>
            <Link to="/control-panels">
              <img className="col-grid-image" src={cpimage} alt=""></img>
            </Link>
          </nav>
          <h3>Control Panels</h3>
          <span>
            <hr className="col-grid-seperator " />
          </span>
          <h4>
            Console plates, control panels and synoptic panels at all dimensions
            can be manufactured in our workshop facility according to the
            customer needs.
          </h4>
        </div>
        <div className="col-grid">
          <nav>
            <Link to="/automation-systems">
              <img className="col-grid-image" src={autimage} alt=""></img>
            </Link>
          </nav>
          <h3>Automation Systems</h3>
          <span>
            <hr className="col-grid-seperator " />
          </span>
          <h4>
            Customer oriented and user friendly Automation Systems are designed
            in compliance with Classification Society Regulations.
          </h4>
        </div>

        <div className="col-grid">
          <nav>
            <Link to="/installation">
              <img className="col-grid-image" src={insimage} alt=""></img>
            </Link>
          </nav>
          <h3>Installation</h3>
          <span>
            <hr className="col-grid-seperator " />
          </span>
          <h4>
            Our Certified Electrician Team is focused on high quality site
            installation in compliance with OHSAS-18001 and Classification
            Society Regulations.
          </h4>
        </div>

        <div className="col-grid">
          <nav>
            <Link to="/project-management/">
              <img className="col-grid-image" src={pmimage} alt=""></img>
            </Link>
          </nav>
          <h3>Project Management & Consultancy</h3>
          <span>
            <hr className="col-grid-seperator " />
          </span>
          <h4>
            Console plates, control panels and synoptic panels at all dimensions
            can be manufactured in our workshop facility according to the
            customer needs.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default OurServices;

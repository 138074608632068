import React from "react";
import { Link, NavLink } from "react-router-dom";

import "./SolutionPartners.css";
import wago from "./images/Wago-logo2.png";
import schneider from "./images/schneider2.png";
import rittal from "./images/rittal.png";
import deif from "./images/deif2.jpg";

const SolutionPartners = (props) => {
  const WagoPath = () => {
    window.open("https://wago.com", "_blank");
  };
  const SchPath = () => {
    window.open("https://schneider-electric.com", "_blank");
  };

  const RitPath = () => {
    window.open("https://rittal.com", "_blank");
  };

  const DeifPath = () => {
    window.open("https://deif.com", "_blank");
  };

  return (
    <div>
      <div className="solutionp-title">
        <h2> OUR SOLUTION PARTNERS </h2>
      </div>
      <div>
        <span className="solutionp-span">
          <hr className="solutionp-seperator" />
        </span>
      </div>

      <div className="solutionp-grid">
        <div className="solutionp-col-grid">
          <img className="logo" src={wago} alt="" onClick={WagoPath}></img>
        </div>
        <div className="solutionp-col-grid">
          <img className="logo" src={schneider} alt="" onClick={SchPath}></img>
        </div>
        <div className="solutionp-col-grid">
          <img className="logo" src={rittal} alt="" onClick={RitPath}></img>
        </div>
        <div className="solutionp-col-grid">
          <img className="logo" src={deif} alt="" onClick={DeifPath}></img>
        </div>
      </div>
    </div>
  );
};

export default SolutionPartners;

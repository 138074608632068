import react from "react";

import "./References.css";

const References = () => {
  return (
    <div className="container">
      <div className="references">
        <div className="references-title">
          <h2> REFERENCES</h2>
        </div>
        <div>
          <span className="references-span">
            <hr className="references-seperator" />
          </span>
        </div>

        <h3>UNDER CONSTRUCTION !!!</h3>
      </div>
    </div>
  );
};

export default References;
